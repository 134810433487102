
import Vue from "vue";
export default class CommonHomepage extends Vue {
  /**
   * card operation: page router
   * @params {operation}: operation type(Decide the page to jump to)
   * @returns void
   */
  cardOperation(operation: string): void {
    if (operation === "view") {
      this["$router"].push("/itemlist");
    }
    if (operation === "new") {
      this["$router"].push("/new");
    }
    if (operation === "schedule") {
      this["$router"].push("/scheduledlist");
    }
  }
}
