
import { Component, Vue } from "vue-property-decorator";
import CommonHomepage from "@/shared/components/CommonHomepage.vue";
@Component({
  name: "Homepage",
  components: {
    CommonHomepage,
  },
})
export default class Homepage extends Vue {}
